const Menu = [
  {
    heading: "menu",
    route: "/trainer",
    pages: [
      {
        heading: "Training Calendar(EDC)",
        route: "/training/training-calendar",
        fontIcon: "fa-calendar",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Trainee Calendar(EDC)",
        route: "/training/trainee-attendance",
        fontIcon: "fa-calendar",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Internship Attendance(EDC)",
        route: "/training/internship-attendance",
        fontIcon: "fa-calendar",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Allow Dropout Trainee(EDC)",
        route: "/training/allowDropout-trainee",
        fontIcon: "fa-calendar",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Attendance Report(EDC)",
        route: "/training/attendance-report",
        fontIcon: "fa-calendar",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Training Completion(EDC)",
        route: "/training/training-completion",
        fontIcon: "fa-calendar",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Training Calendar Report",
        route: "/training/training-calendar-report",
        fontIcon: "fa-calendar",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Trainee Attendance Report",
        route: "/training/trainee-attendance-report",
        fontIcon: "fa-calendar",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
    ],
  },
];

export default Menu;
